import * as React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

import Layout from "../components/layout";
import SecondaryClouds from "../components/secondary-clouds";
import Faq from "../components/faq";
import GlobalData from "../data/global-images";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Pricing = ({ data }) => {
  const images = GlobalData();
  const pricing = data.pricing;

  return (
    <Layout>
      <HelmetDatoCms seo={data.pricing.seoMetaTags} />
      <main>
        <section className="pricing-page js-pricing-page">
          <div className="pricing-page-hero">
            <h2 className="align-center prewrap">{pricing.headline}</h2>
            <p className="pricing-body lede align-center">{pricing.body}</p>
            <div className="pricing-page-hero-switch-container">
              <div className="timeframe">
                <p className="small">{pricing.billedMonthly}</p>
                {pricing.billedMonthlyOffer && (
                  <p className="offer small">{pricing.billedMonthlyOffer}</p>
                )}
              </div>
              <label className="switch">
                <div className="indicator js-price-switch"></div>
                <input type="checkbox" className="checkbox" />
              </label>
              <div className="timeframe">
                <p className="small">{pricing.billedAnnually}</p>
                {pricing.billedAnnuallyOffer && (
                  <p className="offer small">{pricing.billedAnnuallyOffer}</p>
                )}
              </div>
            </div>
          </div>
          <div className="pricing-page-prices-container infront">
            {pricing.prices.map((price) => (
              <div key={price.originalId}>
                <div
                  className={
                    "price-item " + (price.mostPopular && "most-popular")
                  }
                >
                  <h3>{price.headline}</h3>
                  <p className="body">{price.body}</p>
                  <div className="monthly-price">
                    <h4 className="large-price monthly">
                      ${price.monthlyPrice}
                    </h4>
                    <h4 className="large-price annual">${price.yearlyPrice}</h4>
                    <p className="small">
                      {pricing.perMonthCopy}
                      <span className="monthly">{price.perMonth}</span>
                    </p>
                  </div>
                  <ReactMarkdown
                    className="annual annual-price"
                    remarkPlugins={[remarkGfm]}
                  >
                    {price.annualPrice}
                  </ReactMarkdown>
                  <a href={price.btnUrl} className="btn">
                    <span className="btn-text">{price.btnText}</span>
                  </a>
                  {price.featuresIntro && (
                    <p className="price-feature bold">
                      <strong>{price.featuresIntro}</strong>
                    </p>
                  )}
                  {price.features.map((feature) => (
                    <p
                      key={feature.originalId}
                      className="price-feature price-feature-tick"
                    >
                      {feature.bold === true && (
                        <strong>{feature.priceFeature}</strong>
                      )}
                      {feature.bold !== true && (
                        <span>{feature.priceFeature}</span>
                      )}
                    </p>
                  ))}
                  <div className="sticker-container">
                    <div
                      className="sticker sticker--green"
                      style={{
                        backgroundImage: `url('${images.global.stickerCallout.url}')`,
                      }}
                    >
                      {pricing.mostPopularCopy}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* <section className="pricing-faq" id="faq">
                <h2 className="prewrap">{pricing.pricingFaqTitle}</h2>
                <Faq data={pricing.pricingFaq} />
            </section>
*/}
        <SecondaryClouds />
      </main>
    </Layout>
  );
};

export default Pricing;

export const query = graphql`
  query PricingPage($language: String!) {
    pricing: datoCmsPricing(locale: { eq: $language }) {
      billedAnnually
      billedAnnuallyOffer
      billedMonthly
      billedMonthlyOffer
      body
      headline
      mostPopularCopy
      perMonthCopy
      prices {
        originalId
        annualPrice
        body
        btnText
        btnUrl
        featuresIntro
        headline
        monthlyPrice
        yearlyPrice
        mostPopular
        perMonth
        features {
          originalId
          priceFeature
          bold
        }
      }
      pricingFaqTitle
      pricingFaq {
        id
        question
        answer
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
